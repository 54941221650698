<template>
  <div>
    <el-drawer
      :title="title"
      :before-close="handleClose"
      :visible.sync="dialog"
      direction="rtl"
      size="60%"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <div>
        <el-row :gutter="24">
          <el-col :span="6">
            <el-button @click="addDataInfo">新增</el-button>
            <!-- <el-button @click="addDataInfo">批量保存</el-button> -->
          </el-col>
        </el-row>
        <el-table height="600" :data="dataInfoList" border stripe style="width:100%">
          <el-table-column label="序号" :index="indexMethod" fixed type="index" width="50" />
          <el-table-column
            label="操作"
            align="center"
            class-name="small-padding fixed-width"
            width="120"
            fixed
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-if="!scope.row.editShow"
                @click.native.prevent="addEditDetailButton(scope.$index, dataInfoList)"
              >保存</el-button>
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-else
                @click.native.prevent="editButton(scope.$index, dataInfoList)"
              >编辑</el-button>
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click.native.prevent="deleteRow(scope.$index, dataInfoList)"
              >删除</el-button>
            </template>
          </el-table-column>
          <el-table-column label="时间" prop="time" width="130" align="center">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.time"
                filterable
                :disabled="scope.row.editShow"
                placeholder="请选择"
                @change="(val) => checkChange(val, scope.row)"
              >
                <el-option
                  v-for="item in dayDataList"
                  :key="item.key"
                  :label="item.label"
                  :value="item.key"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <!-- <el-table-column label="类型" prop="type" width="110" align="center">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.type"
                filterable
                :disabled="scope.row.id!=null?true:false"
                placeholder="请选择"
                @change="(val) => checkChange(val, scope.row)"
              >
                <el-option
                  v-for="item in typeDetailList"
                  :key="item.key"
                  :label="item.label"
                  :value="item.key"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>-->
          <el-table-column label="景点" prop="tripDetailIds" align="center" width="190">
            <template slot-scope="scope">
              <template v-if="!scope.row.editShow">
                <el-select
                  v-model="scope.row.tripDetailIds"
                  filterable
                  placeholder="请选择"
                  :disabled="scope.row.editShow"
                  value-key="uniqueId"
                  @change="(val) => checkCategoryChange(val, scope.row)"
                >
                  <el-option
                    v-for="item in tripDetailList"
                    :key="item.uniqueId"
                    :label="item.detailName"
                    :value="item"
                  ></el-option>
                </el-select>
              </template>
              <el-input
                disabled
                v-else
                v-model="scope.row.tripDetailName"
              >{{scope.row.tripDetailName}}</el-input>
            </template>
          </el-table-column>
          <!-- <el-table-column label="类型" prop="category" align="center" width="120">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.category"
                filterable
                placeholder="请选择"
                :disabled="scope.row.editShow"
                @change="(val) => checkCategoryChange(val, scope.row)"
              >
                <el-option
                  v-for="item in categoryList"
                  :key="item.key"
                  :label="item.label"
                  :value="item.key"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>-->
          <el-table-column
            label="地址"
            show-overflow-tooltip
            prop="address"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-input
                class="edit-input"
                v-model="scope.row.address"
                controls-position="right"
                disabled
                size="small"
              />
            </template>
          </el-table-column>
          <el-table-column label="预算费用" prop="budgetCost" width="160" align="center">
            <template slot-scope="scope">
              <el-input-number
                class="edit-input"
                v-model="scope.row.budgetCost"
                :disabled="scope.row.editShow"
                controls-position="right"
                placeholder="费用"
                size="small"
              />
            </template>
          </el-table-column>
          <el-table-column label="纬度" prop="latitude" width="100" align="center">
            <template slot-scope="scope">
              <el-input
                class="edit-input"
                v-model="scope.row.latitude"
                :disabled="scope.row.editShow"
              />
            </template>
          </el-table-column>
          <el-table-column label="经度" prop="longitude" width="100" align="center">
            <template slot-scope="scope">
              <el-input
                class="edit-input"
                v-model="scope.row.longitude"
                :disabled="scope.row.editShow"
              />
            </template>
          </el-table-column>

          <el-table-column label="联系电话" prop="phone" width="120" align="center">
            <template slot-scope="scope">
              <el-input
                class="edit-input"
                v-model="scope.row.phone"
                :disabled="scope.row.editShow"
              />
            </template>
          </el-table-column>
          <el-table-column label="顺序" prop="sort" width="110" align="center">
            <template slot-scope="scope">
              <el-input-number
                class="edit-input"
                v-model="scope.row.sort"
                :disabled="scope.row.editShow"
                size="mini"
                controls-position="right"
                style="width: 89px;"
              />
            </template>
          </el-table-column>
          <el-table-column label="是否预订" prop="isBooked" width="70" align="center">
            <template slot-scope="scope">
              <el-switch
                :disabled="scope.row.editShow"
                v-model="scope.row.isBooked"
                active-color="#ff4949"
                inactive-color="#13ce66"
                :active-value="2"
                :inactive-value="1"
              ></el-switch>
            </template>
          </el-table-column>
        </el-table>

        <!-- <div class="demo-drawer__footer">
          <el-button @click="cancelForm">取 消</el-button>
          <el-button type="primary" @click="addFormButton()">确 定</el-button>
        </div>-->
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { getDetailListApi } from "@/api/life/trip/tripDetail";
import {
  addItineraryDetailDataApi,
  editItineraryDetailDataApi,
  deleteItineraryDetailByIdApi,
  getItineraryDetailListApi
} from "@/api/life/trip/itineraryDetail";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      editShow: false,
      title: "新增",
      addEditForm: {},
      timer: null,
      ids: null,
      dataInfoList: [],
      tripId: null,
      categoryList: [],
      types: null,
      typeList: [],
      dayDataList: [],
      tripDetailList: [],
      itineraryId: null
    };
  },
  methods: {
    show(id, type, row) {
      this.itineraryId = row.uniqueId;
      this.typeDetailList = this.$parent.typeDetailList;
      this.dialog = true;
      this.tripId = id;
      this.types = type;
      this.initDataList(row);
      this.dayInitList(row.day, row.early);
      this.selectByIdButton(row);
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then(_ => {
          this.cancelForm();
          this.dialog = false;
        })
        .catch(_ => {});
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      this.editShow = false;
      this.dataInfoList = [];
      this.$parent.selectListTripButton();
    },
    //查询详情
    selectByIdButton(row) {
      // row.
      getItineraryDetailListApi({ itineraryId: this.itineraryId }).then(res => {
        this.dataInfoList = res.result;
      });
    },
    indexMethod(index) {
      return index + 1;
    },
    deleteRow(index, rows) {
      if (rows[index].id != null && rows[index].id != undefined) {
        //需要调用删除接口
        deleteItineraryDetailByIdApi(rows[index].id).then(res => {
          this.selectByIdButton();
        });
      } else {
        rows.splice(index, 1);
      }
    },
    addDataInfo() {
      var dataOneInfo = {
        id: null,
        editShow: false,
        tripId: this.tripId
      };
      this.dataInfoList.push(dataOneInfo);
    },
    initDataList(row) {
      getDetailListApi({ tripId: row.tripId }).then(res => {
        this.tripDetailList = res.result;
      });
    },
    checkChange(val, row) {},
    checkCategoryChange(val, row) {
      this.$set(row, "latitude", val.latitude);
      this.$set(row, "longitude", val.longitude);
      this.$set(row, "address", val.address);
      this.$set(row, "budgetCost", val.budgetCost);
    },
    addEditDetailButton(row, val) {
      val[row].tripDetailId = val[row].tripDetailIds.uniqueId;
      val[row].itineraryId = this.itineraryId;
      if (val[row].id != null) {
        editItineraryDetailDataApi(val[row]).then(res => {
          this.selectByIdButton();
        });
      } else {
        addItineraryDetailDataApi(val[row]).then(res => {
          this.selectByIdButton();
        });
      }
    },
    editButton(row, val) {
      val[row].tripDetailIds = {
        uniqueId: val[row].tripDetailId
      };
      val[row].editShow = false;
    },
    dayInitList(day, early) {
      let i = 1;
      if (1 == early) {
        i = 0;
      }
      let dataList = [];
      for (i; i <= day; i++) {
        var dayData = {
          key: i,
          label: "第" + i + "天"
        };
        dataList.push(dayData);
      }
      this.dayDataList = dataList;
    }
  }
};
</script>
<style scoped>
</style>



