<template>
  <div>
    <el-drawer
      :title="title"
      :before-close="handleClose"
      :visible.sync="dialog"
      direction="rtl"
      size="60%"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <div>
        <div>
          <el-radio-group
            v-model="dayTime"
            style=" margin: 0px 10px;"
            @change="changTimeTypeButton()"
          >
            <el-radio-button
              v-for="city in dayDataList"
              :label="city.key"
              :key="city.key"
              :value="city.key"
            >{{city.label}}</el-radio-button>
          </el-radio-group>
        </div>
        <div>
          <el-row :gutter="24" style=" margin: 10px 0px">
            <el-col :span="4">
              <el-button @click="addDataInfo">新增</el-button>
            </el-col>
            <el-col :span="6">
              <el-button @click="addDataInfo">批量保存</el-button>
            </el-col>
          </el-row>
        </div>

        <el-table height="600" :data="dataInfoList" border stripe style="width:100%">
          <el-table-column label="序号" :index="indexMethod" fixed type="index" width="50" />
          <el-table-column
            label="操作"
            align="center"
            class-name="small-padding fixed-width"
            width="120"
            fixed
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-if="!scope.row.editShow"
                @click.native.prevent="addEditDetailButton(scope.$index, dataInfoList)"
              >保存</el-button>
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-else
                @click.native.prevent="editButton(scope.$index, dataInfoList)"
              >编辑</el-button>
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click.native.prevent="deleteRow(scope.$index, dataInfoList)"
              >删除</el-button>
            </template>
          </el-table-column>
          <el-table-column label="类型" prop="type" width="110" align="center">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.type"
                filterable
                :disabled="scope.row.id!=null?true:false"
                placeholder="请选择"
                @change="(val) => checkChange(val, scope.row)"
              >
                <el-option
                  v-for="item in typeDetailList"
                  :key="item.key"
                  :label="item.label"
                  :value="item.key"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="类别" prop="category" align="center" width="120">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.category"
                filterable
                placeholder="请选择"
                :disabled="scope.row.editShow"
                @change="(val) => checkCategoryChange(val, scope.row)"
              >
                <el-option
                  v-for="item in categoryList"
                  :key="item.key"
                  :label="item.label"
                  :value="item.key"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="名称" prop="name" width="120">
            <template slot-scope="scope">
              <el-input
                class="edit-input"
                v-model="scope.row.name"
                :disabled="scope.row.editShow"
                placeholder="请输入内容"
              />
            </template>
          </el-table-column>
          <el-table-column label="详细地址" prop="address" width="120">
            <template slot-scope="scope">
              <el-input
                class="edit-input"
                v-model="scope.row.address"
                :disabled="scope.row.editShow"
                placeholder="请输入内容"
              />
            </template>
          </el-table-column>
          <el-table-column label="介绍" prop="description" width="120">
            <template slot-scope="scope">
              <el-input
                class="edit-input"
                v-model="scope.row.description"
                :disabled="scope.row.editShow"
                placeholder="请输入内容"
              />
            </template>
          </el-table-column>
          <el-table-column label="平均预算费用" prop="avgBudget" width="120">
            <template slot-scope="scope">
              <el-input
                class="edit-input"
                v-model="scope.row.avgBudget"
                :disabled="scope.row.editShow"
                placeholder="请输入内容"
              />
            </template>
          </el-table-column>
          <el-table-column label="相关链接" prop="relatedLinks" width="120">
            <template slot-scope="scope">
              <el-input
                class="edit-input"
                v-model="scope.row.relatedLinks"
                :disabled="scope.row.editShow"
                placeholder="请输入内容"
              />
            </template>
          </el-table-column>

          <el-table-column label="评分等级" prop="ratingScale" width="120">
            <template slot-scope="scope">
              <el-input
                class="edit-input"
                v-model="scope.row.ratingScale"
                :disabled="scope.row.editShow"
                placeholder="请输入内容"
              />
            </template>
          </el-table-column>

          <el-table-column label="联系方式" prop="contact" width="120">
            <template slot-scope="scope">
              <el-input
                class="edit-input"
                v-model="scope.row.contact"
                :disabled="scope.row.editShow"
                placeholder="请输入内容"
              />
            </template>
          </el-table-column>

          <el-table-column label="时令" prop="seasonal" width="120">
            <template slot-scope="scope">
              <el-input
                class="edit-input"
                v-model="scope.row.seasonal"
                :disabled="scope.row.editShow"
                placeholder="请输入内容"
              />
            </template>
          </el-table-column>
        </el-table>

        <!-- <div class="demo-drawer__footer">
          <el-button @click="cancelForm">取 消</el-button>
          <el-button type="primary" @click="addFormButton()">确 定</el-button>
        </div>-->
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { getItineraryDetailListApi } from "@/api/life/trip/itineraryDetail";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      editShow: false,
      title: "新增",
      addEditForm: {},
      timer: null,
      ids: null,
      dataInfoList: [],
      tripId: null,
      categoryList: [],
      types: null,
      dayDataList: [],
      dayTime: 9999,
      uniqueIds: {}
    };
  },
  methods: {
    show(row, type) {
      this.dialog = true;
      this.uniqueIds = row.uniqueId;
      this.types = type;
      this.initDataList();
      this.dayInitList(row.day, row.early);
      this.changTimeTypeButton();
      if (type == 2) {
        this.selectByIdButton();
      }
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then(_ => {
          this.cancelForm();
          this.dialog = false;
        })
        .catch(_ => {});
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      this.editShow = false;
      this.dataInfoList = [];
      this.$parent.selectListTripButton();
    },
    //查询详情
    selectByIdButton() {},
    indexMethod(index) {
      return index + 1;
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    addDataInfo() {},
    initDataList() {},
    checkChange(val, row) {},
    checkCategoryChange(val, row) {},
    addEditDetailButton(row, val) {},
    editButton(row, val) {},
    dayInitList(day, early) {
      let i = 1;
      if (1 == early) {
        i = 0;
      }
      let dataList = [];
      for (i; i <= day; i++) {
        var dayData = {
          key: i,
          label: "第" + i + "天"
        };
        dataList.push(dayData);
      }

      var other = {
        key: 9999,
        label: "未安排"
      };
      dataList.push(other);
      this.dayDataList = dataList;
    },
    changTimeTypeButton() {
      console.log(this.dayTime);
      this.getTableData()
    },
    getTableData() {
      var data = {
        itineraryId: this.uniqueIds,
        isDistinct: false,
        time:this.dayTime
      };
      getItineraryDetailListApi(data).then(res => {
        this.dataInfoList = res.result;
      });
    }
  }
};
</script>
<style scoped>
</style>



