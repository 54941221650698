<template>
  <div class="tripIndex">
    <div v-show="amapFromShow">
      <!-- 导航区 -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>生活管理</el-breadcrumb-item>
        <el-breadcrumb-item>旅游管理</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- 卡片试图 -->
      <el-tabs v-model="activeName" @tab-click="handleClickTab">
        <!-- 查询区域 -->
        <div>
          <el-form layout="inline" :model="queryParams" :inline="true">
            <el-row :gutter="24">
              <el-col :md="6" :sm="8">
                <el-form-item label="地区" prop="planData">
                  <el-cascader
                    v-model="areaValue"
                    :options="areaList"
                    :props="{ value: 'areaCode', label: 'areaName', checkStrictly: true}"
                    @change="handleChange"
                    filterable
                    ref="cascader"
                    clearable
                  ></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-row>
            <el-col :md="6" :sm="24" style="width:70%">
              <el-button type="primary" @click="selectDataButton">搜索</el-button>
              <el-button type="primary">重置</el-button>
            </el-col>
          </el-row>
        </div>

        <el-tab-pane label="旅游列表" name="first" :key="'first'">
          <!-- 按钮区 -->
          <el-row :gutter="20" style="margin:9px 2px">
            <el-button type="success" icon="el-icon-add" size="min" @click="addTripButton">新增</el-button>
            <el-button type="success" icon="el-icon-add" size="min" @click="ediTripButton">编辑</el-button>
            <el-button
              type="danger"
              icon="el-icon-add"
              size="min"
              @click="deletelifeshopButton"
              disabled
            >删除</el-button>
            <el-button type="success" icon="el-icon-add" size="min" disabled>查看</el-button>
          </el-row>
          <!-- 列表数据 -->
          <el-table :data="dataTripColumnList">
            <el-table-column label="选择" width="70" header-align="center" align="center">
              <template slot-scope="scope">
                <el-radio
                  class="radio"
                  v-model="questionnaireId"
                  :label="scope.row.id"
                  @click.native.stop.prevent="getCurrentRow(scope.row)"
                >&nbsp;</el-radio>
              </template>
            </el-table-column>
            <el-table-column label="地名" align="center" prop="fullName" />
            <el-table-column label="详细地址" align="center" prop="address" />

            <el-table-column label="评分等级" align="center" prop="ratingScale" />
            <el-table-column label="特色小吃" align="center" prop="specialFood" />

            <el-table-column label="计划旅游时间" align="center" prop="planDuration" />
            <el-table-column label="推荐游览时间" align="center" prop="recommendDuration" />
            <el-table-column label="标签" align="center" prop="labels" />

            <el-table-column label="操作" header-align="center" align="center">
              <template slot-scope="scope">
                <el-button
                  @click.native.stop.prevent="addDetailButton(scope.row)"
                  size="small"
                  round
                >添加详情</el-button>
                <el-button
                  @click.native.stop.prevent="selectPlanButton(scope.row)"
                  size="small"
                  round
                >查看计划</el-button>
              </template>
            </el-table-column>
          </el-table>
          <pagination
            :total="queryParams.total"
            :page-size.sync="queryParams.pageSize"
            :current-page.sync="queryParams.currentPage"
          />
        </el-tab-pane>

        <el-tab-pane label="计划列表" ref="child2" name="second" :key="'second'">
          <!-- 按钮区 -->
          <el-row :gutter="20" style="margin:9px 2px">
            <el-button type="success" icon="el-icon-edit" size="min" @click="addItineray()">新增</el-button>
            <el-button type="success" icon="el-icon-add" size="min" @click="editItineray()">编辑</el-button>
            <el-button type="danger" icon="el-icon-add" size="min" disabled>删除</el-button>

            <el-button type="success" icon="el-icon-add" size="min" disabled>查看</el-button>
          </el-row>
          <!-- 列表数据 -->
          <el-table :data="dataColumnList">
            <el-table-column label="选择" width="70" header-align="center" align="center">
              <template slot-scope="scope">
                <el-radio
                  class="radio"
                  v-model="questionnaireId"
                  :label="scope.row.id"
                  @click.native.stop.prevent="getCurrentRow(scope.row)"
                >&nbsp;</el-radio>
              </template>
            </el-table-column>
            <!-- 的省   的市 时间总花费  标题 预估费用 标签 预估费用 -->
            <el-table-column label="起点" align="center" prop="startName" />
            <el-table-column label="目的地" align="center" prop="endName" />
            <el-table-column label="时长" align="center" prop="totalTimeCost" />
            <el-table-column label="标题" align="center" prop="title" />
            <el-table-column label="预估费用" align="center" prop="myBudgetCost" />
            <el-table-column label="标签" align="center" prop="labels" />
            <el-table-column label="计算费用" align="center" prop="budgetCost" />
            <el-table-column label="操作" header-align="center" align="center">
              <template slot-scope="scope">
                <!-- <el-button @click.native.stop.prevent="getCurrentRow(scope.row)" disabled>分享</el-button> -->
                <!-- <el-button @click.native.stop.prevent="getCurrentRow(scope.row)">上传官网</el-button> -->
                <el-button
                  @click.native.stop.prevent="developConfig(scope.row)"
                  size="mini"
                  round
                >① 配置行程</el-button>
                <!-- <el-button
                  @click.native.stop.prevent="supplyButton(scope.row)"
                  size="mini"
                  round
                >② 补充信息</el-button>
                <el-button
                  @click.native.stop.prevent="selectPlan(scope.row)"
                  size="mini"
                  round
                  disabled
                >③ 查看计划</el-button>-->
              </template>
            </el-table-column>
          </el-table>
          <pagination
            :total="queryParams.total"
            :page-size.sync="queryParams.pageSize"
            :current-page.sync="queryParams.currentPage"
          />
        </el-tab-pane>
      </el-tabs>
    </div>

    <amap ref="amapFrom" v-show="!amapFromShow" />
    <editDetail ref="editDetailFrom" />
    <editSave ref="editSaveFrom" />
    <addItinerary ref="addItineraryFrom" style=" height: 100%;" />
    <configItineray ref="configItinerayFrom" />
    <supplyInfos ref="supplyInfosFrom" />
  </div>
</template>

<script>
import { getPageListApi } from "@/api/life/trip/trip";
import { getWorkEnumList } from "@/api/commonApi/commonInternal/commonInternal";
import { getAreaDataApi } from "@/api/system/mune/menuCoupon.js";
import { getItienPageListApi } from "@/api/life/trip/itinerary.js";
import editSave from "./modules/editSave";
import editDetail from "./modules/editSaveDetail";
import amap from "@/views/otherUnits/amap";
import addItinerary from "./modules/addItinerary";
import configItineray from "./modules/configItineray";
import supplyInfos from "./modules/supplyInfo";

export default {
  name: "salary",
  components: {
    editSave,
    amap,
    editDetail,
    addItinerary,
    configItineray,
    supplyInfos
  },
  data() {
    return {
      dataTripColumnList: [],
      dataColumnList: [],
      areaValue: [],
      amapFromShow: true,
      questionnaireId: "",
      gradeList: [],
      queryParams: {
        query: "",
        recordData: "",
        userCompany: "",
        current: 1,
        size: 2,
        shopType: 1,
        provinceCode: "",
        cityCode: "",
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      queryAll: {},
      salaryList: [],
      toggleSearchStatus: false,
      activeName: "first",
      id: null,
      radio: "",
      gradeOption: [],
      areaList: [],
      typeDetailList: [],
      categoryDetailList: [],
      tripIds: null
    };
  },
  watch: {
    "queryParams.currentPage"(val) {
      this.selectDataButton();
    },
    "queryParams.pageSize"(val) {
      this.selectDataButton();
    },
  },
  created() {
    this.initDataList();
    this.selectListTripButton();
  },
  methods: {
    selectDataButton() {
      if (this.activeName == "first") {
        this.selectListTripButton();
      }
      if (this.activeName == "second") {
        this.selectItineraryButton();
      }
    },

    handleClickTab(tab, event) {
      if (tab.name == "first") {
        this.selectListTripButton();
      }
      if (tab.name == "second") {
        this.selectItineraryButton();
      }
      console.log("====", this.tripIds);
      this.id = null;
      this.queryParams = {};
      this.questionnaireId = null;
    },
    initDataList() {
      getWorkEnumList({ enumName: "PlanGradeEnum" }).then(res => {
        this.gradeOption = res.result;
      });
      getWorkEnumList({ enumName: "TripDetailTypeEnum", group: true }).then(
        res => {
          this.typeDetailList = res.result;
        }
      );
      getWorkEnumList({ enumName: "TripDetailTypeEnum" }).then(res => {
        this.categoryDetailList = res.result;
      });
      //加载类型 typeDetailList
      this.areaDataList();
    },
    //再次点击取消单选选中状态
    getCurrentRow(row) {
      //this.questionnaireId 为上一次存储的id与这一次比对判断是否和上次点击的一致
      if (this.questionnaireId == row.id) {
        this.questionnaireId = null;
        this.id = null;
        this.templateSelection = {};
      } else {
        this.questionnaireId = row.id;
        this.id = row.id;
        this.templateSelection = row;
      }
    },
    deletelifeshopButton() {},
    //旅游计划按钮
    selectListTripButton() {
      getPageListApi(this.queryParams).then(res => {
        this.dataTripColumnList = res.result.records;
        this.queryParams.total = res.result.total;
        this.questionnaireId = null;
        this.id = null;
        this.tripIds = null;
        this.templateSelection = {};
      });
    },
    addTripButton() {
      this.$refs.editSaveFrom.showFund(null, 1);
    },
    ediTripButton() {
      if (this.id == undefined || this.id == null)
        return this.$message.error("请选择一条数据");
      console.log(this.id);
      this.$refs.editSaveFrom.showFund(this.id, 2);
    },
    areaDataList() {
      getAreaDataApi({ levelList: [1, 2] }).then(res => {
        this.areaList = res.result;
      });
    },
    handleChange() {
      if (null != this.areaValue[0]) {
        this.queryParams.provinceCode = this.areaValue[0].toString();
        if (null != this.areaValue[1]) {
          this.queryParams.cityCode = this.areaValue[1].toString();
        } else {
          this.queryParams.cityCode = null;
        }
      } else {
        this.queryParams.cityCode = null;
        this.queryParams.provinceCode = null;
      }
    },
    addDetailButton(row) {
      this.getCurrentRow(row);
      this.$refs.editDetailFrom.show(row.uniqueId);
    },
    //这里是计划方法
    selectPlanButton(row) {
      this.activeName = "second";
      this.queryParams.tripId = row.uniqueId;
      this.tripIds = row.uniqueId;
      this.selectItineraryButton();
    },
    selectItineraryButton() {
      getItienPageListApi(this.queryParams).then(res => {
        this.dataColumnList = res.result.records;
        this.queryParams.total = res.result.total;
        this.questionnaireId = null;
        this.id = null;
        this.tripIds = this.queryParams.tripId;
        this.templateSelection = {};
      });
    },
    addItineray() {
      this.$refs.addItineraryFrom.show(this.tripIds, 1, null);
    },
    editItineray() {
      this.$refs.addItineraryFrom.show(this.tripIds, 2, this.id);
    },
    developConfig(row) {
      this.amapFromShow = !this.amapFromShow;
      this.$refs.amapFrom.initData(row,1);
    },
    configItineraryButton(row) {
      this.$refs.configItinerayFrom.show(row.uniqueId, 2, row);
    },
    supplyButton(row) {
      return this.$message.error("还没想好怎么做");
      this.$refs.supplyInfosFrom.show(row, 1, null);
    },
    selectPlan(row) {
      return this.$message.error("还没想好怎么做");
      this.$refs.supplyInfosFrom.show(row, 1, null);
    }
  }
};
</script>

<style lang="less" scoped>
.home_div {
  padding: 0px;
  margin: 0px;
  width: 100%;
  // height: 800px !important;
  position: relative;
}

#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
}

#panel {
  position: fixed;
  background-color: white;
  max-height: 90%;
  overflow-y: auto;
  top: 10px;
  right: 10px;
  width: 280px;
}

#panel .amap-lib-driving {
  border-radius: 4px;
  overflow: hidden;
}

#tripMap {
  position: fixed;
  background-color: red;
  max-height: 90%;
  width: 280px;
  z-index: 999;
  left: 60px;
}
.amap-container {
  height: 980px;
}
.tripIndex {
  height: 100%;
}
</style> 