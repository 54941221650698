<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" width="90%" :before-close="handleClose">
    <el-form :model="dataFrom" label-width="100px" ref="dataFromRef">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="地区" prop="areaValue">
            <el-cascader
             :disabled="editShow"
              v-model="areaValue"
              :options="areaList"
              :props="{ value: 'areaCode', label: 'areaName'}"
              @change="handleChange"
              filterable
              ref="cascader"
            ></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="详细地址" width="120px" prop="address">
            <el-input width="20px" v-model="dataFrom.address" />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="经度" width="120px" prop="latitude">
            <el-input width="20px" v-model="dataFrom.latitude" />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="纬度" width="120px" prop="longitude">
            <el-input width="20px" v-model="dataFrom.longitude" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :md="8" :sm="12">
          <el-form-item label="等级" prop="ratingScale">
            <el-select
              v-model="dataFrom.ratingScale"
              filterable
              placeholder="请选择"
              style="width:70%"
            >
              <el-option
                v-for="item in gradeOption"
                :key="item.key"
                :label="item.value"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :md="8" :sm="12">
          <el-form-item label="预算费用" prop="budgetCost">
            <el-input-number width="20px" v-model="dataFrom.budgetCost" />
          </el-form-item>
        </el-col> -->
        <el-col :span="8">
          <el-form-item label="标签" prop="labels">
            <el-tag
              :key="tag"
              v-for="tag in dynamicTags"
              closable
              :disable-transitions="false"
              @close="handleCloseTag(tag)"
            >{{tag}}</el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="dataFrom.labels"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            ></el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="推荐游览时间" prop="recommendDuration">
            <el-input v-model="dataFrom.recommendDuration" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="计划旅游时间" prop="planDuration">
            <el-input v-model="dataFrom.planDuration" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="特色小吃" prop="specialFood">
            <el-input v-model="dataFrom.specialFood" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="游玩准备" prop="tips">
            <el-input v-model="dataFrom.tips" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="备注" prop="remark">
            <el-input v-model="dataFrom.remark" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="介绍" prop="summary">
            <el-input v-model="dataFrom.summary" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel()">取 消</el-button>
      <el-button type="primary" @click="editSaveButton">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { selectlifeshopByIdApi } from "@/api/life/shop/lifeshop";
import {
  addDataApi,
  editDataApi,
  getSelectByIdApi
} from "@/api/life/trip/trip";

export default {
  name: "saveEdit",
  data() {
    return {
      areaList: {},
      gradeList: {},
      areaValue: [],
      gradeOption: [],
      dialogVisible: false,
      dataFrom: {},
      dataFromRef: {},
      ids: null,
      types: 1,
      editShow: false,
      title: null,

      dynamicTags: [],
      inputVisible: false
    };
  },
  methods: {
    //打开弹窗 1新增 2编辑
    showFund(id, type) {
      this.areaList = this.$parent.areaList;
     
      this.gradeOption = this.$parent.gradeOption;
      this.initEnumList();
      this.ids = id;
      this.dialogVisible = true;
      this.types = type;
      if (type == 2) {
         this.editShow=true
        this.selectDataButton();
      }
    },
    selectDataButton() {
      getSelectByIdApi(this.ids).then(res => {
        this.dataFrom = res.result;
        this.areaValue = res.result.areaList;
        if (null != res.result.labels || "" != res.result.labels) {
          this.dynamicTags = res.result.labels.split(",");
        }
      });
    },
    cancel() {
      this.areaValue = [];
      this.dynamicTags = [];
      this.dataFrom = {};
      this.dataFromRef = {};
      this.dialogVisible = false;
      //调用父组件的查询
      this.$parent.selectListTripButton();
      this.ids = null;
      this.$parent.id = null;
      this.$parent.questionnaireId = null;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          this.cancel();
        })
        .catch(_ => {});
    },
    editSaveButton() {
      this.dataFrom.labels = this.dynamicTags.toString();
      if (this.types == 1) {
        addDataApi(this.dataFrom).then(res => {
          this.cancel();
        });
      }
      if (this.types == 2) {
        editDataApi(this.dataFrom).then(res => {
          this.cancel();
        });
      }
    },
    initEnumList() {},
    handleChange() {
      let data = this.$refs.cascader.getCheckedNodes()[0].pathLabels;
      let center = this.$refs.cascader
        .getCheckedNodes()[0]
        .data.center.split(",");
      this.dataFrom.latitude = center[0];
      this.dataFrom.longitude = center[1];

      this.dataFrom.provinceName = data[0];
      this.dataFrom.cityName = data[1];
      this.dataFrom.districtName = data[2];
      this.dataFrom.provinceCode = this.areaValue[0];
      this.dataFrom.cityCode = this.areaValue[1];
      this.dataFrom.areaCode = this.areaValue[2];
    },
    handleCloseTag(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.dataFrom.labels;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.dataFrom.labels = "";
    }
  }
};
</script>
<style lang="less" scoped>
</style> 